import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/Layout";
import { TextBanner } from "../components/molecules/TextBanner";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Content from "../components/Content";
import { LayoutBoxMinimal } from "../components/atoms/LayoutBox";

// eslint-disable-next-line
export const FocusAreaTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  featuredimage,
  screenshot,
}) => {
  const featuredImage = getImage(featuredimage) || featuredimage;
  const resolvedScreenshot = getImage(screenshot) || screenshot;

  const ToolContent = contentComponent || Content;

  return (
    <>
      <section className="section py-1 px-3">
        <LayoutBoxMinimal>
          <a className="level py-3" onClick={() => navigate(-1)}>
            ← Back
          </a>
        </LayoutBoxMinimal>
      </section>
      <TextBanner title={title} />
      <section className="section pt-0">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="is-flex is-flex-direction-row-reverse py-3">
                <div className="has-text-centered pl-3 my-auto">
                  {typeof featuredImage === "object" &&
                  featuredImage.extension !== "svg" ? (
                    <GatsbyImage image={featuredImage} alt={`${title} Icon`} />
                  ) : (
                    <img
                      src={featuredImage.publicURL || featuredImage}
                      alt={`${title} Icon`}
                    />
                  )}
                </div>
                <p
                  className="subtitle pl-3 my-auto has-text-right"
                  style={{ fontStyle: "italic", maxWidth: "80%" }}
                >
                  {description}
                </p>
              </div>
              <div className="columns">
                <div className="column is-3 has-text-centered my-auto">
                  {typeof resolvedScreenshot === "object" &&
                  resolvedScreenshot.extension !== "svg" ? (
                    <GatsbyImage
                      image={resolvedScreenshot}
                      alt={`${title} Icon`}
                    />
                  ) : (
                    <img
                      src={resolvedScreenshot.publicURL || resolvedScreenshot}
                      alt={`Screenshot for ${title}`}
                    />
                  )}
                </div>
                <div className="column is-9 my-auto">
                  <ToolContent content={content} />
                </div>
              </div>
              <div className="has-text-centered my-3">
                <Link to="/focus-areas">
                  <button className="button is-primary is-light is-rounded">
                    All focus areas
                  </button>
                </Link>
              </div>
              {tags && tags.length ? (
                <div>
                  <h4>Tags</h4>
                  <div className="tags">
                    {tags.map((tag) => (
                      <span key={tag + `tag`} className="tag is-small">
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

FocusAreaTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  featuredimage: PropTypes.object,
  screenshot: PropTypes.object,
};

const FocusArea = ({ data }) => {
  const { markdownRemark: tool } = data;

  return (
    <Layout
      title={`${tool.frontmatter.title} | Digital Tools`}
      description={tool.frontmatter.description}
      imageURL={tool.frontmatter.featuredimage?.publicURL}
      imageAlt={`Featured image for focus area ${tool.frontmatter.title}`}
    >
      <FocusAreaTemplate
        content={tool.html}
        contentComponent={({ content }) => (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
        description={tool.frontmatter.description}
        featuredimage={tool.frontmatter.featuredimage}
        screenshot={tool.frontmatter.screenshot}
        tags={tool.frontmatter.tags}
        title={tool.frontmatter.title}
      />
    </Layout>
  );
};

FocusArea.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default FocusArea;

export const pageQuery = graphql`
  query FocusAreaByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, height: 100)
          }
          extension
          publicURL
        }
        screenshot {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 600)
          }
          extension
          publicURL
        }
      }
    }
  }
`;
